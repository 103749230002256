import React from 'react'
import Link from 'next/link'
import { Anchor, Box, Button, Group, Space, Text } from '@mantine/core'
import BigNumber from 'bignumber.js'
import { useSetAtom } from 'jotai'

import { InlineLoader, StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_SUPPORTS_ADD_TO_DEX, DEX_POOL_MINIMUM_APY, getDexInfoFromDenom } from '@/config'
import { notify } from '@/contexts/notifications'
import { useDexWallet, useSelectedCoin } from '@/contexts/wallet'
import { useMount } from '@/hooks'
import { StandardTransactionError } from '@/wallet-utils'
import { AnalyticsTracker } from '@/page-components/shared'
import {
  stakeModeAtom,
  withdrawStTokenToDexModalIsOpenAtom,
  withdrawStTokenToDexTransactionAtom
} from '@/page-components/Stake/atoms'
import { assert } from '@/utils'
import { useDexPoolApyQuery } from '../queries'
import { useStakeClassicModal } from './StakeClassicModalProvider'

const StakeClassicModalStepFour: React.FC = () => {
  const {
    liquidStakeTransaction,
    broadcastLiquidStake,
    isBroadcastingLiquidStake,
    isBroadcastLiquidStakeSuccessful,
    broadcastLiquidStakeError
  } = useStakeClassicModal()

  const denom = useSelectedCoin()

  const dexAccount = useDexWallet()

  const setWithdrawStTokenToDexModalIsOpen = useSetAtom(withdrawStTokenToDexModalIsOpenAtom)

  const setWithdrawStTokenToDexTransaction = useSetAtom(withdrawStTokenToDexTransactionAtom)

  const setStakeMode = useSetAtom(stakeModeAtom)

  const { data: withdrawStTokenToDexApy, error: withdrawStTokenToDexApyError } = useDexPoolApyQuery()

  const { complete, forceClose, close, handleClose } = useStepperContext()

  const handleStep = async () => {
    await broadcastLiquidStake()

    complete()
  }

  const handleCloseCallback = () => {
    // It means it was closed after the broadcast
    if (!isBroadcastingLiquidStake) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the stake completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleAddToWithdrawStTokenToDex = () => {
    assert(liquidStakeTransaction, 'Unable to proceed to osmosis liquidity pools while stake transaction is null.')

    setStakeMode('classic')
    setWithdrawStTokenToDexTransaction(liquidStakeTransaction)
    close()
    setWithdrawStTokenToDexModalIsOpen(true)
  }

  if (
    broadcastLiquidStakeError instanceof StandardTransactionError &&
    broadcastLiquidStakeError.description === 'INSUFFICIENT_FUNDS'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            Insufficient funds for gas. Get STRD, ATOM or TIA on{' '}
            <Anchor href="https://app.osmosis.zone/?from=ATOM&to=OSMO" target="_blank">
              Osmosis
            </Anchor>
            .
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastLiquidStakeError}
      />
    )
  }

  if (broadcastLiquidStakeError instanceof StandardTransactionError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={broadcastLiquidStakeError.description}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStep}>
              Try again
            </Button>
          </>
        }
        error={broadcastLiquidStakeError}
      />
    )
  }

  if (broadcastLiquidStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            This transfer could not be completed. Your tokens are on Stride, but have not been staked. Please try again.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStep}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  // It's important that this is placed behind a success condition to avoid the step from
  // flashing this message on the first render given that the mutation only runs after the mount.
  if (isBroadcastLiquidStakeSuccessful) {
    const dexInfo = getDexInfoFromDenom(denom)

    // If [there are errors] or [apy has been loaded by is below the minimum], we'll hide the APR.
    const isAprDataInsufficient =
      Boolean(withdrawStTokenToDexApyError) ||
      (withdrawStTokenToDexApy && withdrawStTokenToDexApy.apy <= DEX_POOL_MINIMUM_APY)

    return (
      <>
        <StepperModalContent
          title="Success!"
          description={
            <>
              <Box>
                You liquid staked {denom} on Stride and st
                {denom} has been added to your wallet.
              </Box>

              {CHAIN_SUPPORTS_ADD_TO_DEX[denom] && dexInfo && dexAccount ? (
                <>
                  <Space h="xs" />
                  {isAprDataInsufficient ? (
                    <Box>Do you want to add it to the liquidity pool on {dexInfo.name} to earn an extra APR?</Box>
                  ) : (
                    <Box>
                      Do you want to add it to the liquidity pool on {dexInfo.name} to earn an{' '}
                      <strong>
                        extra{' '}
                        {withdrawStTokenToDexApy == null ? <InlineLoader /> : formatApy(withdrawStTokenToDexApy.apy)}%
                        APR
                      </strong>
                      ?
                    </Box>
                  )}
                  x
                </>
              ) : null}
            </>
          }
          actions={
            <>
              {CHAIN_SUPPORTS_ADD_TO_DEX[denom] && dexInfo && dexAccount ? (
                <>
                  <Button onClick={handleAddToWithdrawStTokenToDex}>Add to DEX</Button>

                  <Link href="/earn" passHref legacyBehavior>
                    <Button component="a" variant="outline" color="dark">
                      See other options
                    </Button>
                  </Link>
                </>
              ) : (
                <Button onClick={close} variant="outline" color="dark">
                  Exit
                </Button>
              )}
            </>
          }
          footer={
            denom === 'INJ' ? (
              <Group spacing={0}>
                <Text sx={(t) => ({ color: t.colors.gray[7] })}>Injective Treasure Hunt secret word:&nbsp;</Text>

                <Text weight={600} sx={(t) => ({ color: t.colors.gray[9] })}>
                  GUM
                </Text>
              </Group>
            ) : null
          }
        />

        <AnalyticsTracker eventName="classic_liquid_stake_complete" />
      </>
    )
  }

  return (
    <StepperModalContent
      title={`Staking your ${denom}...`}
      description="Just a few seconds, unless the network is congested"
    />
  )
}

const formatApy = (apy: number) => {
  return new BigNumber(apy).multipliedBy(100).decimalPlaces(2).toString()
}

export { StakeClassicModalStepFour }
