import React from 'react'
import Link from 'next/link'
import { Box, Button, Group, Space, Text } from '@mantine/core'
import BigNumber from 'bignumber.js'
import { useAtomValue, useSetAtom } from 'jotai'

import { InlineLoader, StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_INFO_LIST, CHAIN_SUPPORTS_ADD_TO_DEX, DEX_POOL_MINIMUM_APY, getDexInfoFromDenom } from '@/config'
import { notify } from '@/contexts/notifications'
import { useDexWallet, useSelectedCoin } from '@/contexts/wallet'
import { assert } from '@/utils'
import { formatDenom } from '@/wallet-utils'
import { AnalyticsTracker } from '@/page-components/shared'
import {
  stakeAmountAtom,
  stakeAutopilotRecipientAtom,
  stakeModeAtom,
  withdrawStTokenToDexModalIsOpenAtom,
  withdrawStTokenToDexTransactionAtom
} from '../atoms'
import { useDexPoolApyQuery, useHostZoneQuery } from '../queries'
import { useStakeAutopilotModal } from './StakeAutopilotModalProvider'

const StakeAutopilotModalStepThree: React.FC = () => {
  const amount = useAtomValue(stakeAmountAtom)

  const { stakeAutopilotTransaction, isBroadcastingStakeAutopilot } = useStakeAutopilotModal()

  const denom = useSelectedCoin()

  const dexAccount = useDexWallet()

  const setWithdrawStTokenToDexModalIsOpen = useSetAtom(withdrawStTokenToDexModalIsOpenAtom)

  const setWithdrawStTokenToDexTransaction = useSetAtom(withdrawStTokenToDexTransactionAtom)

  const setStakeMode = useSetAtom(stakeModeAtom)

  const { data: hostZone } = useHostZoneQuery()

  const { data: withdrawStTokenToDexApy, error: withdrawStTokenToDexApyError } = useDexPoolApyQuery()

  const formattedAmount = `${formatDenom(amount, 3)} ${denom}`

  const stakedAmount = amount / Number(hostZone?.redemption_rate ?? 1)

  const formattedStakedAmount = `${formatDenom(stakedAmount, 5)} st${denom}`

  const { close, handleClose } = useStepperContext()

  const stakeAutopilotRecipient = useAtomValue(stakeAutopilotRecipientAtom)

  const handleCloseCallback = () => {
    // It means it was closed after the broadcast
    if (!isBroadcastingStakeAutopilot) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the stake completes.")
  }
  handleClose(handleCloseCallback)

  const handleAddToWithdrawStTokenToDex = () => {
    assert(stakeAutopilotTransaction, 'Unable to proceed to dex transfer while stake transaction is null.')
    setStakeMode('autopilot')
    setWithdrawStTokenToDexTransaction(stakeAutopilotTransaction)
    close()
    setWithdrawStTokenToDexModalIsOpen(true)
  }

  // It's important that this is placed behind a success condition to avoid the step from
  // flashing this message on the first render given that the mutation only runs after the mount.
  const dexInfo = getDexInfoFromDenom(denom)

  // If [there are errors] or [apy has been loaded by is below the minimum], we'll hide the APR.
  const isAprDataMissing =
    Boolean(withdrawStTokenToDexApyError) ||
    (withdrawStTokenToDexApy && withdrawStTokenToDexApy.apy <= DEX_POOL_MINIMUM_APY)

  return (
    <>
      <StepperModalContent
        title="Success!"
        description={
          <>
            <Box>
              You staked {formattedAmount} on Stride and {formattedStakedAmount} has been added to your{' '}
              {stakeAutopilotRecipient === 'STRD'
                ? 'wallet'
                : `${CHAIN_INFO_LIST[stakeAutopilotRecipient].chainName} account`}
              .
            </Box>

            {stakeAutopilotRecipient === 'STRD' && CHAIN_SUPPORTS_ADD_TO_DEX[denom] && dexInfo && dexAccount ? (
              <>
                <Space h="xs" />

                {isAprDataMissing ? (
                  <Box>Do you want to add it to the liquidity pool on {dexInfo.name} to earn an extra APR?</Box>
                ) : (
                  <Box>
                    Do you want to add it to the liquidity pool on {dexInfo.name} to earn an{' '}
                    <strong>
                      extra{' '}
                      {withdrawStTokenToDexApy == null ? (
                        <InlineLoader />
                      ) : (
                        new BigNumber(withdrawStTokenToDexApy.apy).multipliedBy(100).decimalPlaces(2).toString()
                      )}
                      % APR
                    </strong>
                    ?
                  </Box>
                )}
              </>
            ) : null}
          </>
        }
        actions={
          stakeAutopilotRecipient === 'STRD' && CHAIN_SUPPORTS_ADD_TO_DEX[denom] && dexInfo && dexAccount ? (
            <>
              <Button onClick={handleAddToWithdrawStTokenToDex}>Add to DEX</Button>

              <Link href="/earn" passHref legacyBehavior>
                <Button component="a" variant="outline" color="dark">
                  See other options
                </Button>
              </Link>
            </>
          ) : (
            <Button onClick={close} variant="outline" color="dark">
              Exit
            </Button>
          )
        }
        footer={
          denom === 'INJ' ? (
            <Group spacing={0}>
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>Injective Treasure Hunt secret word:&nbsp;</Text>

              <Text weight={600} sx={(t) => ({ color: t.colors.gray[9] })}>
                GUM
              </Text>
            </Group>
          ) : null
        }
      />

      <AnalyticsTracker eventName="autopilot_liquid_stake_complete" />
    </>
  )
}

export { StakeAutopilotModalStepThree }
